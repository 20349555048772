@import "@csstools/normalize.css"

$base-colors ?= {
	bg: #111,
	blue: #479BFF
}


////////////////////////////////////////////////////////////////////////////////

html, body
	width 100%
	height 100%
	padding 0
	margin 0
	touch-action none // Disable "refresh" action on mobile (when a user scroll to the top of the screen)
	background: $base-colors["bg"]

body, input
	font-family /*system-ui,*/ -apple-system, BlinkMacSystemFont,
		"Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans",
		"Helvetica Neue", sans-serif

::selection
	color #EEE
	text-shadow 0 3px 1px #000
	background-color: $base-colors["bg"]

.rn
	&__canvas
		position fixed
		top 0
		left 0
		width 100%
		height 100%
		opacity 0

		&--show
			opacity 1
			transition opacity ease-in-out 1000ms

	&__text
		position absolute
		top 50%
		left 50%
		color black
		transform translate(-50%, -50%)

	&__svg
		width 200px
		max-width 100%
		height auto

	&-svg
		width 150px
		max-width 100%

	&-svg-old
		display none

		&__squares
			fill none
			stroke: $base-colors["blue"]
			stroke-width 11px

		&__letters
			fill: $base-colors["blue"]
			fill-rule nonzero


////////////////////////////////////////////////////////////////////////////////
